/**
 * 引入服务模块
 */
import service from './service'
/**
 * 引入内容类型的常量
 */
import {CONTENT_TYPE} from '@/constants'
/**
 * 引入用户仓库的钩子，不带依赖
 */
import {useUserStoreWithOut} from '@/store/modules/user'

/**
 * 发起请求的封装函数
 * @param option 请求配置对象，包含请求的各个参数
 * @returns 返回服务请求的Promise
 */


const request = (option) => {
    // 解构请求配置对象中的各项参数
    const {url, method, params, data, headers, responseType} = option;

    // 使用用户仓库钩子获取用户仓库实例
    const userStore = useUserStoreWithOut();

    // 获取令牌键名和令牌值
    const tokenKey = userStore.getTokenKey || 'Authorization';
    const token = userStore.getToken;

    // 确保只有在存在令牌时才添加Authorization头
    let authHeader = '';
    if (token) {
        authHeader = `${tokenKey}: Bearer ${token}`;
    }

    // 调用服务模块的request方法发起请求，并根据配置组装请求头
    return service.request({
        url,
        method,
        params,
        data,
        responseType,
        headers: {
            'Content-Type': CONTENT_TYPE,
            ...authHeader ? {Authorization: authHeader.replace(`${tokenKey}: `, '')} : {},
            ...headers
        }
    });
};


/**
 * 导出包含各种请求方法的对象
 */
export default {
    /**
     * 发起GET请求
     * @param option 请求配置对象
     * @returns 返回GET请求的Promise
     */
    get: <T = any>(option: AxiosConfig) => {
        return request({method: 'get', ...option}) as Promise<IResponse<T>>
    },
    /**
     * 发起POST请求
     * @param option 请求配置对象
     * @returns 返回POST请求的Promise
     */
    post: <T = any>(option: AxiosConfig) => {
        return request({method: 'post', ...option}) as Promise<IResponse<T>>
    },
    /**
     * 发起DELETE请求
     * @param option 请求配置对象
     * @returns 返回DELETE请求的Promise
     */
    delete: <T = any>(option: AxiosConfig) => {
        return request({method: 'delete', ...option}) as Promise<IResponse<T>>
    },
    /**
     * 发起PUT请求
     * @param option 请求配置对象
     * @returns 返回PUT请求的Promise
     */
    put: <T = any>(option: AxiosConfig) => {
        return request({method: 'put', ...option}) as Promise<IResponse<T>>
    },
    /**
     * 取消指定URL的请求
     * @param url 要取消的请求的URL，可以是字符串或字符串数组
     */
    cancelRequest: (url: string | string[]) => {
        return service.cancelRequest(url)
    },
    /**
     * 取消所有请求
     */
    cancelAllRequest: () => {
        return service.cancelAllRequest()
    }
}

