/**
 * 创建一个axios实例，并配置请求和响应拦截器。
 * 这个实例用于封装API请求，提供取消请求的功能，并处理请求和响应的异常。
 */
import axios, { AxiosError } from 'axios'
import { defaultRequestInterceptors, defaultResponseInterceptors } from './config'

import { AxiosInstance, InternalAxiosRequestConfig, RequestConfig, AxiosResponse } from './types'
import { ElMessage } from 'element-plus'
import { REQUEST_TIMEOUT } from '@/constants'

// 定义API的基础路径
export const PATH_URL = import.meta.env.VITE_API_BASE_PATH

// 使用Map来管理AbortController实例，以便于取消请求
const abortControllerMap: Map<string, AbortController> = new Map()

// 创建一个axios实例，并配置基础URL和超时时间
const axiosInstance: AxiosInstance = axios.create({
  timeout: REQUEST_TIMEOUT,
  baseURL: PATH_URL
})

// 请求拦截器：为每个请求关联一个AbortController，以支持取消请求
axiosInstance.interceptors.request.use((res: InternalAxiosRequestConfig) => {
  const controller = new AbortController()
  const url = res.url || ''
  res.signal = controller.signal
  abortControllerMap.set(
    import.meta.env.VITE_USE_MOCK === 'true' ? url.replace('/mock', '') : url,
    controller
  )
  return res
})

// 响应拦截器：清除对应请求的AbortController，释放资源
axiosInstance.interceptors.response.use(
  (res: AxiosResponse) => {
    const url = res.config.url || ''
    abortControllerMap.delete(url)
    // 这里不能做任何处理，否则后面的 interceptors 拿不到完整的上下文了
    return res
  },
  (error: AxiosError) => {
    console.log('err： ' + error) // for debug
    ElMessage.error(error.message)
    return Promise.reject(error)
  }
)

// 使用默认的请求和响应拦截器
axiosInstance.interceptors.request.use(defaultRequestInterceptors)
axiosInstance.interceptors.response.use(defaultResponseInterceptors)

/**
 * API服务封装。
 * 提供请求发送、请求取消等功能。
 */
const service = {
  /**
   * 发送请求。
   * @param config 请求配置。
   * @returns 返回一个Promise，解析为响应数据或错误。
   */
  request: (config: RequestConfig) => {
    return new Promise((resolve, reject) => {
      // 如果存在自定义的请求拦截器，则先处理自定义拦截器
      if (config.interceptors?.requestInterceptors) {
        config = config.interceptors.requestInterceptors(config as any)
      }

      // 发送请求，并处理响应或错误
      axiosInstance
        .request(config)
        .then((res) => {
          resolve(res)
        })
        .catch((err: any) => {
          reject(err)
        })
    })
  },

  /**
   * 取消一个或多个请求。
   * @param url 要取消的请求的URL或URL数组。
   */
  cancelRequest: (url: string | string[]) => {
    const urlList = Array.isArray(url) ? url : [url]
    for (const _url of urlList) {
      abortControllerMap.get(_url)?.abort()
      abortControllerMap.delete(_url)
    }
  },

  /**
   * 取消所有正在进行的请求。
   */
  cancelAllRequest() {
    for (const [_, controller] of abortControllerMap) {
      controller.abort()
    }
    abortControllerMap.clear()
  }
}

export default service
