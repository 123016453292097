import { AxiosResponse, InternalAxiosRequestConfig } from './types'
import { ElMessage } from 'element-plus'
import qs from 'qs'
import { SUCCESS_CODE, TRANSFORM_REQUEST_DATA } from '@/constants'
import { useUserStoreWithOut } from '@/store/modules/user'
import { objToFormData } from '@/utils'

/**
 * 配置默认的请求拦截器。
 *
 * 该函数用于在请求发送前进行一些预处理，例如根据请求方法和内容类型对请求数据进行序列化，
 * 或者对GET请求的参数进行编码。这样可以在不修改每个请求的实现的情况下，统一处理请求数据的格式。
 *
 * @param config Axios的内部请求配置对象，包含请求方法、URL、数据、头信息等。
 * @returns 返回经过处理的请求配置对象。
 */
const defaultRequestInterceptors = (config: InternalAxiosRequestConfig) => {
  // 如果是POST请求且内容类型为application/x-www-form-urlencoded，则使用qs库对数据进行序列化
  if (
    config.method === 'post' &&
    config.headers['Content-Type'] === 'application/x-www-form-urlencoded'
  ) {
    config.data = qs.stringify(config.data)
  } else if (
    TRANSFORM_REQUEST_DATA &&
    config.method === 'post' &&
    config.headers['Content-Type'] === 'multipart/form-data' &&
    !(config.data instanceof FormData)
  ) {
    // 如果配置了转换请求数据且为POST请求、内容类型为multipart/form-data且数据不是FormData实例，则将数据转换为FormData
    config.data = objToFormData(config.data)
  }
  // 如果是GET请求且带有参数，则将参数编码并追加到URL上
  if (config.method === 'get' && config.params) {
    let url = config.url as string
    url += '?'
    const keys = Object.keys(config.params)
    for (const key of keys) {
      if (config.params[key] !== void 0 && config.params[key] !== null) {
        url += `${key}=${encodeURIComponent(config.params[key])}&`
      }
    }
    url = url.substring(0, url.length - 1)
    config.params = {}
    config.url = url
  }
  return config
}

/**
 * 配置默认的响应拦截器。
 *
 * 该函数用于在请求响应返回后进行一些处理，例如根据响应的状态码显示错误信息，
 * 或者在收到401未授权响应时，调用退出登录逻辑。这样可以统一处理响应的错误情况，
 * 提高代码的可维护性和用户体验。
 *
 * @param response Axios的响应对象，包含响应数据、状态码、头信息等。
 * @returns 如果响应正常，则返回响应数据；否则，根据错误信息进行处理并可能触发退出登录操作。
 */
const defaultResponseInterceptors = (response: AxiosResponse) => {
  // 如果响应的responseType为blob，则直接返回响应，不做处理
  if (response?.config?.responseType === 'blob') {
    // 如果是文件流，直接过
    return response
  } else if (response.data.code === SUCCESS_CODE) {
    // 如果响应数据的code字段表示成功，则直接返回响应数据
    return response.data
  } else {
    // 如果响应数据的code字段表示错误，显示错误信息
    ElMessage.error(response?.data?.message)
    // 如果响应数据的code字段为401未授权，则调用退出登录逻辑
    if (response?.data?.code === 40104) {
      const userStore = useUserStoreWithOut()
      userStore.logout()
    }
  }
}

export { defaultResponseInterceptors, defaultRequestInterceptors }

